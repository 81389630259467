<template>
  <div v-if="loaded">
    <h1>{{nomesquadra}}</h1>
    <partita v-for="partita in partite" :partita="partita" :key="partita.id" />
  </div>
  <div v-else>
    <h1>Caricamento in Corso</h1>
    <h2>Abbia pazienza, caro fan di <i>{{nomesquadra}}</i></h2>
  </div>
</template>

<script>

import Axios from 'axios';
import Partita from '../components/Partita.vue';

export default {
  name: 'Home',
  created () {
    this.getPartite();
  },
  components: {
    Partita
  },
  data: function() {
    return {
      partite: [],
      loaded: false,
      nomesquadra: this.$route.params.nomesquadra
    }
  },
  methods: {
    async getPartite() {
      try {
        let res = await Axios.get("http://algoritmo.carminezacc.com:3000/partite/"+this.nomesquadra);
        this.partite = res.data;
        this.loaded = true;
      } catch {
        alert("Big errore");
      }
      
    },
  }
}

</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

</style>
