<template>
   <div>
      <h1>{{ partita.sq_casa }}-{{ partita.sq_trasferta }}</h1>
      <h1>{{ partita.casa }}-{{ partita.trasferta }}</h1>
      <h2>{{ getDateText() }}</h2>
    </div>
</template>

<script>

var Partita = /** @class */ (function () {
    function Partita(sq_casa, sq_trasferta, casa, trasferta, date, id) {
        this.sq_casa = sq_casa;
        this.sq_trasferta = sq_trasferta;
        this.casa = casa;
        this.trasferta = trasferta;
        this.date = date;
        this.id = id;
    }
    return Partita;
}());
export default {
  name: 'Partita',
  props: {
    partita: Partita
  },
  methods: {
      getDateText() {
          var date = new Date(this.partita.date);
          return `Giocata il ${date.getDate()}/${date.getMonth()+1}`;
      }
  }
}
</script>

<style scoped>

div {
    margin: auto;

    width: 500px;
    outline: black;
    outline-style: dashed;
}

</style>
